import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import { Form, Button, Container, Jumbotron } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const PasswordForgetPage = () => (
  <Container>
    <PasswordForgetForm />
  </Container>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <Jumbotron>
        <h4>Forgot Password</h4>
        <Form onSubmit={this.onSubmit}>
          <Form.Group controlId="forgetPassword">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              name="email"
              onChange={this.onChange}
              type="email"
              placeholder="Enter email"
              value={this.state.email}
            />
            {error && (
              <Form.Text className="text-danger">{error.message}</Form.Text>
            )}
          </Form.Group>
          <Button disabled={isInvalid} type="submit">
            Reset Password
          </Button>
        </Form>
      </Jumbotron>
    );
  }
}

const PasswordForgetLink = () => (
  <Container fluid={true}>
    <LinkContainer to={ROUTES.PASSWORD_FORGET}>
      <Button>Forgot Password?</Button>
    </LinkContainer>
  </Container>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
