import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { SliderPicker, CirclePicker } from "react-color";
import { SizeMe } from "react-sizeme";

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSliderColor: "#FF0000",
      currentColor: "#FFFFFF"
    };
  }

  handleCircleColorChange = color => {
    this.setState({ currentColor: color.hex });
  };

  handleSliderColorChange = color => {
    this.setState({
      currentSliderColor: color.hex,
      currentColor: color.hex
    });
  };

  selectColor = () => {
    this.props.selectColorCallback(this.state.currentColor);
  };

  selectRandomColor = () => {
    this.props.selectColorCallback("random");
  };

  selectRainbowColor = () => {
    this.props.selectColorCallback("rainbow");
  };

  render() {
    return (
      <Container fluid={true} className="mb-3">
        <Row className="justify-content-md-center">
          <SizeMe>
            {({ size }) => (
              <Container fluid={true} className="mb-3">
                <Row className="justify-content-md-center">
                  <Col className="justify-content-md-center">
                    <CirclePicker
                      circleSize={Math.min(size.width / 5, 100)}
                      circleSpacing={
                        (size.width - 3 * Math.min(size.width / 5, 100)) / 3
                      }
                      width={size.width + "px"}
                      height={Math.min(size.width / 5, 100)}
                      color={this.state.currentColor}
                      onChangeComplete={this.handleCircleColorChange}
                      colors={[
                        "#000000",
                        this.state.currentSliderColor,
                        "#FFFFFF"
                      ]}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 justify-content-md-center">
                  <Col>
                    <SliderPicker
                      color={this.state.currentSliderColor}
                      onChangeComplete={this.handleSliderColorChange}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </SizeMe>
        </Row>
        <Row className="justify-content-md-center mb-3">
          <Col>
            <Button variant="secondary" onClick={this.selectRandomColor} block>
              Random Color
            </Button>
          </Col>
          <Col>
            <Button variant="secondary" onClick={this.selectRainbowColor} block>
              Rainbow Color
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <Button variant="success" onClick={this.selectColor} block>
              Add Color
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ColorPicker;
