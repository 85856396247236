import React, { Component } from "react";
import { Row, Col, Button, Accordion, Table, Card } from "react-bootstrap";

class PresetDisplay extends Component {
  handleDeleteClick = () => {
    this.props.handleDeleteClick(this.props.presetId);
  };

  handleApplyClick = () => {
    this.props.handleApplyClick(this.props.presetId);
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={this.props.presetId}
              >
                {this.props.preset.name}
              </Accordion.Toggle>
            </Col>
            <Col>
              <Button onClick={this.handleDeleteClick} variant="danger" block>
                Delete
              </Button>
            </Col>
            <Col>
              <Button onClick={this.handleApplyClick} block>
                Apply
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse eventKey={this.props.presetId}>
          <Card.Body>
            <h5>Animation</h5>
            <Table variant="light" striped bordered>
              <tbody>
                <tr>
                  <td>Receivers</td>
                  <td>
                    <ul>
                      {Object.entries(this.props.preset.receivers).map(
                        (receiver, idx) => (
                          <li key={idx}>{receiver[1].username}</li>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{this.props.preset.value.animationType}</td>
                </tr>
                <tr>
                  <td>Speed</td>
                  <td>{this.props.preset.value.animationSpeed}</td>
                </tr>
                <tr>
                  <td>Brightness</td>
                  <td>{this.props.preset.value.brightness}</td>
                </tr>
                <tr>
                  <td>Serpentine Order?</td>
                  <td>{this.props.preset.value.serpentine ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Display Text</td>
                  <td>{this.props.preset.value.text}</td>
                </tr>
              </tbody>
            </Table>

            <h5>Colors</h5>

            {this.props.preset.value.colors &&
              this.props.preset.value.colors.length > 0 && (
                <Table variant="light" striped bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.preset.value.colors.map((color, i) => {
                      if (color === "rainbow") {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>Rainbow</td>
                          </tr>
                        );
                      } else if (color === "random") {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>Random</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td style={{ backgroundColor: color }}>&nbsp;</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default PresetDisplay;
