import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import { Button, Badge } from "react-bootstrap";

class ReceiversPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      receivers: [this.props.lightId],
      devices: this.props.receivers
    };
  }

  componentDidMount() {
    this.props.firebase.db
      .ref("devices")
      .once("value")
      .then(snapshot => {
        var modifiedDevices = snapshot.val();

        Object.entries(modifiedDevices).map((device, deviceKey) => {
          modifiedDevices[device[0]].selected = false;

          if (!modifiedDevices[device[0]].claimed) {
            delete modifiedDevices[device[0]];
          }
        });

        modifiedDevices[this.props.lightId].selected = true;

        this.props.onReceiversChanged(modifiedDevices);

        this.setState({
          loading: false,
          devices: modifiedDevices
        });
      });
  }

  handleChange = event => {
    var devices = this.state.devices;

    if (event.target.id in devices) {
      devices[event.target.id].selected = !devices[event.target.id].selected;
      this.props.onReceiversChanged(devices);
    }
  };

  render() {
    var myDevice = this.state.devices[this.props.lightId];

    return (
      !this.state.loading && (
        <>
          <Button
            block
            variant={myDevice.selected ? "info" : "dark"}
            id={this.props.lightId}
            onClick={this.handleChange}
          >
            My Light
            {"   "}
            {"online" in this.state.devices[this.props.lightId] &&
            this.state.devices[this.props.lightId].online ? (
              <Badge pill variant="success">
                Online
              </Badge>
            ) : (
              <Badge pill variant="danger">
                Offline
              </Badge>
            )}
          </Button>
          <hr style={{ backgroundColor: "LightSlateGrey", height: 4 }} />
          {Object.entries(this.state.devices).map(
            (device, key) =>
              device[0] !== this.props.lightId && (
                <React.Fragment key={key}>
                  <Button
                    block
                    variant={device[1].selected ? "info" : "dark"}
                    id={device[0]}
                    onClick={this.handleChange}
                  >
                    {device[1].username}
                    {"   "}
                    {"online" in device[1] && device[1].online ? (
                      <Badge pill variant="success">
                        Online
                      </Badge>
                    ) : (
                      <Badge pill variant="danger">
                        Offline
                      </Badge>
                    )}
                  </Button>
                  <br />
                </React.Fragment>
              )
          )}
        </>
      )
    );
  }
}

export default withFirebase(ReceiversPicker);
