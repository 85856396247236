import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import { Form, Button, Jumbotron } from "react-bootstrap";

const INITIAL_STATE = {
  lightId: "",
  lightIdIsValid: false
};

class LightClaimer extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { lightId } = this.state;

    this.props.firebase.db
      .ref("users/" + this.props.firebase.auth.currentUser.uid)
      .once("value")
      .then(snapshot => {
        var user = snapshot.val();

        user.lightId = lightId;

        this.props.firebase.db
          .ref("users/" + this.props.firebase.auth.currentUser.uid)
          .set(user)
          .then(() => {
            this.props.firebase.db
              .ref("devices/" + user.lightId)
              .set({
                claimed: true,
                username: user.username,
                allowExternalMessages: false
              })
              .then(() => {
                this.props.lightClaimed();
              });
          });
      });

    event.preventDefault();
  };

  onChange = event => {
    var devIdFormat = /^[A-F0-9:-]+$/;

    if (event.target.value.match(devIdFormat)) {
      this.props.firebase.db
        .ref("devices/" + event.target.value)
        .once("value")
        .then(snapshot => {
          this.setState({
            lightIdIsValid: snapshot.val() && snapshot.val().claimed === false
          });
        });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        lightIdIsValid: false
      });
      return;
    }

    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { lightId, lightIdIsValid } = this.state;

    const isValid = lightIdIsValid;

    return (
      <Jumbotron>
        <h3>Claim a Light:</h3>
        <Form onSubmit={this.onSubmit}>
          <Form.Group controlId="userName">
            <Form.Label>Light ID</Form.Label>
            <Form.Control
              name="lightId"
              value={lightId}
              onChange={this.onChange}
              type="text"
              placeholder="The ID of your light"
            />
            {!lightIdIsValid && (
              <Form.Text className="text-danger">
                Invalid or already claimed Light ID
              </Form.Text>
            )}
          </Form.Group>
          <Button disabled={!!!isValid} type="submit">
            Claim
          </Button>
        </Form>
      </Jumbotron>
    );
  }
}

export default withFirebase(LightClaimer);
