import React, { Component } from "react";

import { AuthUserContext, withAuthorization } from "../Session";
import { PasswordForgetForm } from "../PasswordForget";
import PasswordChangeForm from "../PasswordChange";
import FirmwareUpdateForm from "../FirmwareUpdate";
import AllowExternalMessages from "../AllowExternalMessages";
import { Container, Jumbotron } from "react-bootstrap";

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      lightId: "",
      username: ""
    };
  }

  componentDidMount() {
    this.props.firebase.db
      .ref("users/" + this.props.firebase.auth.currentUser.uid)
      .once("value")
      .then(snapshot => {
        this.setState({
          loading: false,
          lightId: snapshot.val().lightId,
          username: snapshot.val().username
        });
      });
  }

  render() {
    return (
      !this.state.loading && (
        <AuthUserContext.Consumer>
          {authUser => (
            <Container>
              <Jumbotron style={{ marginTop: 15 }}>
                <h4>Account Info</h4>
                <h5>
                  <b>Name: </b>
                  {this.state.username}
                </h5>
                <h5>Email: {authUser.email}</h5>
                <h5>
                  Light Id: {this.state.lightId ? this.state.lightId : "None"}
                </h5>
              </Jumbotron>

              {this.state.lightId && (
                <AllowExternalMessages lightId={this.state.lightId} />
              )}
              <PasswordForgetForm />
              <PasswordChangeForm />
              {this.state.lightId && (
                <FirmwareUpdateForm lightId={this.state.lightId} />
              )}
            </Container>
          )}
        </AuthUserContext.Consumer>
      )
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
