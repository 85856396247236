import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import { withAuthorization } from "../Session";

import {
  Container,
  Row,
  Button,
  Spinner,
  Modal,
  Accordion
} from "react-bootstrap";

import PresetDisplay from "../PresetDisplay";

class Presets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      presets: {},
      showConfirmDelete: false,
      lightId: "",
      presetDeletionCandidate: "",
      displayRejected: false,
      rejectedUsernames: []
    };
  }

  handleConfirmDeleteShow = presetIndex => {
    this.setState({
      presetDeletionCandidate: presetIndex,
      showConfirmDelete: true
    });
  };

  handleConfirmDeleteClose = () => {
    this.setState({ showConfirmDelete: false });
  };

  handleDisplayRejectedClose = () => {
    this.setState({ displayRejected: false });
  };

  deletePreset = event => {
    console.log(this.state.presetDeletionCandidate);

    this.props.firebase.db
      .ref(
        "users/" +
          this.props.firebase.auth.currentUser.uid +
          "/presets/" +
          this.state.presetDeletionCandidate
      )
      .remove()
      .then(() => {
        this.setState({
          showConfirmDelete: false,
          presetDeletionCandidate: ""
        });

        this.componentDidMount();
      });
  };

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.db
      .ref("users/" + this.props.firebase.auth.currentUser.uid)
      .once("value")
      .then(snapshot => {
        this.setState({
          loading: false,
          lightId: snapshot.val().lightId,
          presets: snapshot.val().presets
        });
      });
  }

  handleApplyPreset = presetId => {
    this.props.firebase.db
      .ref("devices/")
      .once("value")
      .then(snapshot => {
        var devicesSnapshot = snapshot.val();
        var receivingIds = [];
        var rejectedUsernames = [];

        Object.entries(this.state.presets[presetId].receivers).map(
          (device, deviceKey) => {
            if (device[0] === this.state.lightId) {
              receivingIds.push(device[0]);
            } else if (devicesSnapshot[device[0]].allowExternalMessages) {
              receivingIds.push(device[0]);
            } else {
              rejectedUsernames.push(device[1].username);
            }
            return 0;
          }
        );

        this.props.firebase.auth.currentUser.getIdToken().then(token => {
          receivingIds.forEach(receiverId => {
            var req = new XMLHttpRequest();

            req.onload = function() {
              //TODO add success handler
              console.log(req.responseText);
            };

            req.onerror = function() {
              //TODO add error handler
              console.log(req.responseText);
            };

            req.open(
              "POST",
              process.env.REACT_APP_FUNCTION_URL + "applyLight",
              true
            );

            req.setRequestHeader("Authorization", "Bearer " + token);

            req.setRequestHeader(
              "Content-Type",
              "application/json;charset=UTF-8"
            );

            console.log("sending settings to:");
            console.log(receiverId);
            console.log(this.state.presets[presetId].value);

            req.send(
              JSON.stringify({
                targetDevice: receiverId,
                lightConfig: this.state.presets[presetId].value
              })
            );
          });
        });

        var displayRejected = rejectedUsernames.length !== 0;

        this.setState({
          rejectedUsernames: rejectedUsernames,
          displayRejected: displayRejected
        });
      });
  };

  render() {
    return (
      <Container fluid={true} style={{ marginTop: 15 }}>
        {this.state.loading && (
          <Row className="justify-content-md-center">
            <Spinner animation="border" />
          </Row>
        )}
        {!this.state.loading && !this.state.presets && (
          <h4>No presets have been saved yet! Go add some!</h4>
        )}
        {!this.state.loading && this.state.presets && (
          <Accordion defaultActiveKey="0">
            {Object.entries(this.state.presets).map((preset, idx) => {
              return (
                <PresetDisplay
                  key={preset[0]}
                  presetId={preset[0]}
                  preset={preset[1]}
                  handleDeleteClick={this.handleConfirmDeleteShow}
                  handleApplyClick={this.handleApplyPreset}
                />
              );
            })}
          </Accordion>
        )}
        {!this.state.loading &&
          this.state.presets &&
          this.state.presetDeletionCandidate && (
            <Modal
              show={this.state.showConfirmDelete}
              onHide={this.handleConfirmDeleteClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Delete "
                  {this.state.presets[this.state.presetDeletionCandidate].name}
                  "?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete "
                {this.state.presets[this.state.presetDeletionCandidate].name}"?
                This action cannot be undone!
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={this.handleConfirmDeleteClose}
                >
                  Cancel
                </Button>
                <Button variant="danger" onClick={this.deletePreset}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          )}

        {!this.state.loading && this.state.presets && (
          <Modal
            show={this.state.displayRejected}
            onHide={this.handleDisplayRejectedClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Rejected Lights</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your settings were rejected by the following lights because they
              are not accepting external messages at this time:
              <ul>
                {this.state.rejectedUsernames.map((username, key) => (
                  <li key={key}>{username}</li>
                ))}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={this.handleDisplayRejectedClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(Presets));
