import React, { Component } from "react";

import { Button } from "react-bootstrap";

import { withFirebase } from "../Firebase";

class SignOutButton extends Component {
  render() {
    return (
      <Button onClick={this.props.firebase.doSignOut} block>
        Sign Out
      </Button>
    );
  }
}

export default withFirebase(SignOutButton);
