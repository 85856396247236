import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import { Button, Jumbotron } from "react-bootstrap";

class FirmwareUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, allowExt: false };
  }

  componentDidMount() {
    this.props.firebase.db
      .ref("devices/" + this.props.lightId)
      .once("value")
      .then(snapshot => {
        this.setState({
          loading: false,
          allowExt: snapshot.val().allowExternalMessages
        });
      });
  }

  handleChange = event => {
    this.props.firebase.db
      .ref("devices/" + this.props.lightId + "/allowExternalMessages")
      .set(!this.state.allowExt)
      .then(() => {
        this.setState({
          allowExt: !this.state.allowExt
        });
      });
  };

  render() {
    return (
      <Jumbotron>
        <h4>Allow External Messages?</h4>
        <div>
          If this is "Yes", other accounts will be able to apply settings to
          your light at any time.
        </div>
        {!this.state.loading && (
          <Button
            onClick={this.handleChange}
            variant={this.state.allowExt ? "success" : "danger"}
            block
            style={{ marginTop: 15 }}
          >
            {this.state.allowExt ? "Yes" : "No"}
          </Button>
        )}
      </Jumbotron>
    );
  }
}

export default withFirebase(FirmwareUpdateForm);
