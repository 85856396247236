import React, { Component } from "react";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

class AnimationPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animationSpeed: 100,
      animationType: "Static",
      brightness: 50,
      serpentine: true,
    };
  }

  onAnimationSpeedChange = (value) => {
    this.props.onAnimationSpeedChange(value);
    this.setState({
      animationSpeed: value,
    });
  };

  onAnimationTypeChange = (event) => {
    this.props.onAnimationTypeChange(event.target.value);

    this.setState({
      animationType: event.target.value,
    });
  };

  onBrightnessChange = (value) => {
    this.props.onBrightnessChange(value);
    this.setState({
      brightness: value,
    });
  };

  onSerpentineChange = (event) => {
    this.props.onSerpentineChange(!this.state.serpentine);
    this.setState({
      serpentine: !this.state.serpentine,
    });
  };

  onTextChange = (event) => {
    this.props.onDisplayTextChange(event.target.value);
  };

  render() {
    return (
      <Container fluid={true}>
        <h5>Animation Type</h5>
        <Form>
          <Form.Group as={Col} controlId="animationType">
            <Form.Control as="select" onChange={this.onAnimationTypeChange}>
              <option>Static</option>
              <option>Blink</option>
              <option>Color Travel</option>
              <option>Color Wipe</option>
              <option>Inverse Color Wipe</option>
              <option>Rainbow</option>
              <option>Sequential Color Wipe</option>
              <option>Strobe</option>
              <option>Wraparound</option>
              <option>Full Random</option>
              <option>Single Random</option>
              <option>Breathe</option>
              <option>Color Fade</option>
              <option>Color Bounce</option>
              <option>Sparkle</option>
              <option>Travel Flash</option>
              <option>Larson Scanner</option>
              <option>Fireplace</option>
              <option>Particle Flame</option>
              <option>Particle Painter</option>
              <option>Particle Spin</option>
              <option>Starfield</option>
              <option>Particle Rain</option>
              <option>Static Text</option>
              <option>Text Scroll</option>
              <option>Stock Ticker</option>
              <option>Clock</option>
            </Form.Control>
          </Form.Group>
        </Form>

        {(this.state.animationType === "Text Scroll" ||
          this.state.animationType === "Static Text" ||
          this.state.animationType === "Stock Ticker") && (
          <>
            <h5>Text To Display</h5>
            <Form>
              <Form.Group controlId="ledText">
                <Form.Control
                  type="text"
                  placeholder="LED Text"
                  onChange={this.onTextChange}
                />
              </Form.Group>
            </Form>
          </>
        )}

        <h5 className="mt-2">Use Serpentine Order?</h5>
        <Button
          onClick={this.onSerpentineChange}
          variant={this.state.serpentine ? "primary" : "success"}
          block
        >
          {this.state.serpentine ? "Serpentine" : "Non-Serpentine"}
        </Button>

        <h5 className="mt-3">Animation Speed</h5>

        <Row className="justify-content-md-center">
          <Col>
            <h5 className="text-center">{this.state.animationSpeed}ms</h5>
          </Col>
        </Row>

        <Slider
          value={this.state.animationSpeed}
          onChange={this.onAnimationSpeedChange}
          min={1}
          max={1000}
        />

        <h5 className="mt-3">Brightness</h5>

        <Row className="justify-content-md-center">
          <Col>
            <h5 className="text-center">{this.state.brightness}</h5>
          </Col>
        </Row>

        <Slider
          value={this.state.brightness}
          onChange={this.onBrightnessChange}
          min={0}
          max={255}
        />
      </Container>
    );
  }
}

export default AnimationPicker;
