import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import { withAuthorization } from "../Session";

import {
  Form,
  Container,
  Col,
  Row,
  Button,
  Spinner,
  Jumbotron,
  Modal,
  Tab,
  Tabs
} from "react-bootstrap";

import ColorsPicker from "../ColorsPicker";
import AnimationPicker from "../AnimationPicker";
import LightClaimer from "../LightClaimer";
import ReceiversPicker from "../ReceiversPicker";

class LightAdjustmentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showSaveDialogue: false,
      saveNameValid: false,
      presetSaveName: "",
      lightId: "",
      receivers: {},
      rejectedUsernames: [],
      lightConfig: {
        colors: [],
        animationSpeed: 100,
        animationType: "Static",
        brightness: 100,
        serpentine: true,
        text: ""
      }
    };

    this.applyLight = this.applyLight.bind(this);
  }

  savePreset = () => {
    var presetReceivers = {};

    Object.entries(this.state.receivers).map((receiver, idx) => {
      if (receiver[1].selected) {
        presetReceivers[receiver[0]] = {
          username: receiver[1].username
        };
      }

      return 0;
    });

    var newPreset = {
      name: this.state.presetSaveName,
      receivers: presetReceivers,
      value: this.state.lightConfig
    };

    this.props.firebase.db
      .ref("users/" + this.props.firebase.auth.currentUser.uid + "/presets")
      .push(newPreset)
      .then(() => {
        this.setState({
          showSaveDialogue: false,
          saveNameValid: false,
          presetSaveName: ""
        });
      });
  };

  handleSaveDialogueShow = () => {
    this.setState({ showSaveDialogue: true });
  };

  handleSaveDialogueClose = () => {
    this.setState({ showSaveDialogue: false });
  };

  updatePresetSaveName = e => {
    if (e.target.value === "") {
      this.setState({
        saveNameValid: false,
        presetSaveName: ""
      });
    } else {
      this.setState({
        saveNameValid: true,
        presetSaveName: e.target.value
      });
    }
  };

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.db
      .ref("users/" + this.props.firebase.auth.currentUser.uid)
      .once("value")
      .then(snapshot => {
        this.setState({
          loading: false,
          lightId: snapshot.val().lightId
        });
      });
  }

  applyLight = () => {
    this.props.firebase.db
      .ref("devices/")
      .once("value")
      .then(snapshot => {
        var devicesSnapshot = snapshot.val();
        var receivingIds = [];
        var rejectedUsernames = [];

        Object.entries(this.state.receivers).map((device, deviceKey) => {
          if (device[0] === this.state.lightId) {
            if (device[1].selected) {
              receivingIds.push(device[0]);
            }
          } else if (device[1].selected) {
            if (devicesSnapshot[device[0]].allowExternalMessages) {
              receivingIds.push(device[0]);
            } else {
              rejectedUsernames.push(device[1].username);
            }
          }
          return 0;
        });

        this.props.firebase.auth.currentUser.getIdToken().then(token => {
          receivingIds.forEach(receiverId => {
            var req = new XMLHttpRequest();

            req.onload = function() {
              //TODO add success handler
              console.log(req.responseText);
            };

            req.onerror = function() {
              //TODO add error handler
              console.log(req.responseText);
            };

            req.open(
              "POST",
              process.env.REACT_APP_FUNCTION_URL + "applyLight",
              true
            );

            req.setRequestHeader("Authorization", "Bearer " + token);

            req.setRequestHeader(
              "Content-Type",
              "application/json;charset=UTF-8"
            );

            var configToApply = this.state.lightConfig;

            if (configToApply.animationType === "Clock") {
              configToApply.text = new Date().getTimezoneOffset().toString();
            }

            req.send(
              JSON.stringify({
                targetDevice: receiverId,
                lightConfig: configToApply
              })
            );
          });
        });

        var displayRejected = rejectedUsernames.length !== 0;

        this.setState({
          rejectedUsernames: rejectedUsernames,
          displayRejected: displayRejected
        });
      });
  };

  updateColors = colorsArray => {
    var currentConfig = this.state.lightConfig;
    currentConfig.colors = colorsArray;
    this.setState({ lightConfig: currentConfig });
  };

  updateAnimationSpeed = animationSpeed => {
    var currentConfig = this.state.lightConfig;
    currentConfig.animationSpeed = animationSpeed;
    this.setState({ lightConfig: currentConfig });
  };

  updateAnimationType = animationType => {
    var currentConfig = this.state.lightConfig;
    currentConfig.animationType = animationType;
    this.setState({ lightConfig: currentConfig });
  };

  updateBrightness = value => {
    var currentConfig = this.state.lightConfig;
    currentConfig.brightness = value;
    this.setState({ lightConfig: currentConfig });
  };

  updateSerpentine = value => {
    var currentConfig = this.state.lightConfig;
    currentConfig.serpentine = value;
    this.setState({ lightConfig: currentConfig });
  };

  updateDisplayText = value => {
    var currentConfig = this.state.lightConfig;
    currentConfig.text = value.substring(0, 512);
    this.setState({ lightConfig: currentConfig });
  };

  lightClaimed = () => {
    this.componentDidMount();
  };

  updateReceivers = value => {
    this.setState({ receivers: value });
  };

  handleDisplayRejectedClose = () => {
    this.setState({ displayRejected: false });
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <Row className="justify-content-md-center">
            <Spinner animation="border" />
          </Row>
        )}

        {!this.state.loading && this.state.lightId && (
          <>
            <Container fluid={true}>
              <Tabs
                fill
                defaultActiveKey="animation"
                id="light-profile"
                transition={false}
                style={{
                  fontWeight: "bold",
                  backgroundColor: "LightSlateGrey"
                }}
              >
                <Tab eventKey="animation" title="Animation">
                  <Jumbotron>
                    <AnimationPicker
                      onAnimationSpeedChange={this.updateAnimationSpeed}
                      onAnimationTypeChange={this.updateAnimationType}
                      onBrightnessChange={this.updateBrightness}
                      onSerpentineChange={this.updateSerpentine}
                      onDisplayTextChange={this.updateDisplayText}
                    />
                  </Jumbotron>
                </Tab>

                <Tab eventKey="colors" title="Colors">
                  <Jumbotron>
                    <ColorsPicker onColorsChanged={this.updateColors} />
                  </Jumbotron>
                </Tab>

                <Tab eventKey="receivers" title="Receivers">
                  <Jumbotron>
                    <ReceiversPicker
                      onReceiversChanged={this.updateReceivers}
                      lightId={this.state.lightId}
                      receivers={this.state.receivers}
                    />
                  </Jumbotron>
                </Tab>
              </Tabs>

              <Jumbotron>
                <Row className="justify-content-md-center">
                  <Col>
                    <Button onClick={this.applyLight} block>
                      Apply
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={this.handleSaveDialogueShow} block>
                      Save as Preset
                    </Button>
                  </Col>
                </Row>
              </Jumbotron>

              <Modal
                show={this.state.showSaveDialogue}
                onHide={this.handleSaveDialogueClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Save as Preset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="presetSaveName">
                      <Form.Label>Preset Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Preset Name"
                        value={this.state.presetSaveName}
                        onChange={this.updatePresetSaveName}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={this.handleSaveDialogueClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={this.savePreset}
                    disabled={!this.state.saveNameValid}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={this.state.displayRejected}
                onHide={this.handleDisplayRejectedClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Rejected Lights</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Your settings were rejected by the following lights because
                  they are not accepting external messages at this time:
                  <ul>
                    {this.state.rejectedUsernames.map((username, key) => (
                      <li key={key}>{username}</li>
                    ))}
                  </ul>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="success"
                    onClick={this.handleDisplayRejectedClose}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </Container>
          </>
        )}

        {!this.state.loading && !this.state.lightId && (
          <LightClaimer lightClaimed={this.lightClaimed} />
        )}
      </>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(LightAdjustmentPage));
