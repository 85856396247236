import React, { Component } from "react";
import { Button } from "react-bootstrap";

class ColorDeleteButton extends Component {
  handleDeleteClick = () => {
    this.props.handleDeleteClick(this.props.myIndex);
  };

  render() {
    return (
      <Button variant="danger" onClick={this.handleDeleteClick} block>
        Delete
      </Button>
    );
  }
}

export default ColorDeleteButton;
