import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import { Button, Jumbotron, Modal } from "react-bootstrap";

const INITIAL_STATE = {
  showConfirmation: false
};

class FirmwareUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onClick = () => {
    this.setState({ showConfirmation: true });
  };

  updateFirmware = () => {
    this.props.firebase.auth.currentUser.getIdToken().then(token => {
      console.log(
        "Sending request to",
        process.env.REACT_APP_FUNCTION_URL + "applyLight",
        "with ID token in Authorization header."
      );
      var req = new XMLHttpRequest();
      req.onload = function() {
        //TODO add success handler
        console.log(req.responseText);
      };
      req.onerror = function() {
        //TODO add error handler
      };
      req.open("POST", process.env.REACT_APP_FUNCTION_URL + "applyLight", true);
      req.setRequestHeader("Authorization", "Bearer " + token);
      req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      req.send(
        JSON.stringify({
          targetDevice: this.props.lightId,
          command: "UPDATE FIRMWARE"
        })
      );
      this.handleConfirmationClose();
    });
  };

  handleConfirmationClose = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    return (
      <Jumbotron>
        <h4>Update Firmware</h4>
        <Button onClick={this.onClick}>Update Firmware</Button>

        <Modal
          show={this.state.showConfirmation}
          onHide={this.handleConfirmationClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Firmware</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to enter firmware update mode on your light?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleConfirmationClose}>
              Cancel
            </Button>
            <Button onClick={this.updateFirmware}>Yes</Button>
          </Modal.Footer>
        </Modal>
      </Jumbotron>
    );
  }
}

export default withFirebase(FirmwareUpdateForm);
