import React, { Component } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import ColorPicker from "../ColorPicker";
import ColorDeleteButton from "../ColorDeleteButton";

class ColorsPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colors: []
    };
  }

  handleColorAddition = color => {
    var newColors = [...this.state.colors, color];
    this.handleColorChange(newColors);
    this.setState({ colors: newColors });
  };

  handleColorDelete = index => {
    var tempColors = [...this.state.colors];
    tempColors.splice(index, 1);
    this.handleColorChange(tempColors);
    this.setState({ colors: tempColors });
  };

  handleColorChange = colors => {
    this.props.onColorsChanged(colors);
  };

  render() {
    return (
      <Container fluid={true} className="mb-3">
        <h5>Pick Colors</h5>
        <ColorPicker selectColorCallback={this.handleColorAddition} />
        {this.state.colors.length > 0 && (
          <Row>
            <Col>
              <Table variant="light" striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Color</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.colors.map((color, i) => {
                    if (color === "rainbow") {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>Rainbow</td>
                          <td>
                            <ColorDeleteButton
                              key={i}
                              myIndex={i}
                              handleDeleteClick={this.handleColorDelete}
                            >
                              Delete
                            </ColorDeleteButton>
                          </td>
                        </tr>
                      );
                    } else if (color === "random") {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>Random</td>
                          <td>
                            <ColorDeleteButton
                              key={i}
                              myIndex={i}
                              handleDeleteClick={this.handleColorDelete}
                            >
                              Delete
                            </ColorDeleteButton>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td style={{ backgroundColor: color }}>&nbsp;</td>
                          <td>
                            <ColorDeleteButton
                              key={i}
                              myIndex={i}
                              handleDeleteClick={this.handleColorDelete}
                            >
                              Delete
                            </ColorDeleteButton>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default ColorsPicker;
