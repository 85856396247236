import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Landing extends Component {
  render() {
    return (
      <Container>
        <h3>Welcome!</h3>
        <h4>Please login to get started!</h4>
      </Container>
    );
  }
}

export default Landing;
