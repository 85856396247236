import React from "react";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";

import { Button, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Navigation = () => (
  <>
    <AuthUserContext.Consumer>
      {authUser => (authUser ? <NavigationAuth /> : <NavigationNonAuth />)}
    </AuthUserContext.Consumer>
  </>
);

const NavigationAuth = () => (
  <Navbar bg="dark" variant="dark">
    <LinkContainer to={ROUTES.LANDING}>
      <Navbar.Brand>Sadbois</Navbar.Brand>
    </LinkContainer>
    <Nav>
      <LinkContainer to={ROUTES.LIGHT_ADJUSTMENT}>
        <Nav.Link>Light</Nav.Link>
      </LinkContainer>
      <LinkContainer to={ROUTES.PRESETS}>
        <Nav.Link>Presets</Nav.Link>
      </LinkContainer>
      <LinkContainer to={ROUTES.ACCOUNT}>
        <Nav.Link>Account</Nav.Link>
      </LinkContainer>
    </Nav>
    <Nav className="ml-auto">
      <SignOutButton />
    </Nav>
  </Navbar>
);

const NavigationNonAuth = () => (
  <Navbar bg="dark" variant="dark">
    <LinkContainer to={ROUTES.LANDING}>
      <Navbar.Brand>Sadbois</Navbar.Brand>
    </LinkContainer>

    <Nav className="ml-auto">
      <LinkContainer to={ROUTES.SIGN_IN}>
        <Button>Login</Button>
      </LinkContainer>
    </Nav>
  </Navbar>
);
export default Navigation;
